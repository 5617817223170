import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { InViewportModule } from 'ng-in-viewport';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { ParticlesModule } from 'angular-particle';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

import { NavigationService } from './services/navigation.service';

import { NavbarComponent } from './layout/navbar/navbar.component';
import { OrganizationChartComponent } from './organization-chart/organization-chart.component';
import { FooterComponent } from './layout/footer/footer.component';
import { EmptyComponent } from './layout/empty/empty.component';
import { GgcsmxComponent } from './ggcsmx/ggcsmx.component';

import { KonamiModule } from 'ngx-konami';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    OrganizationChartComponent,
    FooterComponent,
    EmptyComponent,
    GgcsmxComponent
  ],
  imports: [
    BrowserModule,
    InViewportModule,
    AppRoutingModule,
    ParticlesModule,
    AnimateOnScrollModule.forRoot(),
    KonamiModule
  ],
  providers: [NavigationService],
  bootstrap: [AppComponent]
})
export class AppModule {}
