import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  padding: number = 0;
  previousUrl: string = null;
  currentUrl: string = null;
  konamiCodeActive: boolean = false;
  changed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  offset = {
    '': 0,
    home: 0,
    organigrama: 0,
    'innovacion-gubernamental': 0,
    direcciones: 0
  };
  onHideNavbar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onHideFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, private route: ActivatedRoute) {
    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url.substr(1);
        this.changed.next(true);
      });
  }

  scrollCurrentRoute(): void {
    this.scrollSection(this.currentUrl);
  }

  scrollSection(route: string): void {
    this.changed.next(true);
    let scrollTop = this.offset[route];

    const routeBehavior = {
      '': 'smooth',
      'innovacion-gubernamental': 'smooth',
      direcciones: 'smooth'
    };

    const behavior =
      this.previousUrl === null
        ? this.currentUrl === 'organigrama' || this.currentUrl === ''
          ? 'auto'
          : 'smooth'
        : this.previousUrl === 'organigrama'
          ? 'auto'
          : routeBehavior[this.currentUrl];

    if (this.currentUrl !== '' && this.currentUrl !== 'organigrama') {
      scrollTop -= this.padding;
    }

    window.scroll({
      top: scrollTop,
      left: 0,
      behavior: behavior
    });
  }

  hideNavbar(flag: boolean): void {
    this.onHideNavbar.next(flag);
  }

  hideFooter(flag: boolean): void {
    this.onHideFooter.next(flag);
  }
}
