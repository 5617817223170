import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationService } from '../services/navigation.service';
import { Map, tileLayer, icon, Marker, LatLng } from 'leaflet';
import * as leaflet from 'leaflet';

@Component({
  selector: 'app-ggcsmx',
  templateUrl: './ggcsmx.component.html',
  styleUrls: ['./ggcsmx.component.scss']
})
export class GgcsmxComponent implements OnInit {
  hideNavbar: boolean = true;
  hidefooter: boolean = true;

  map: any;
  marker: any;

  constructor(
    private navigationService: NavigationService,
    private meta: Meta,
    private titleService: Title
  ) {
    // Se indica el title
    this.titleService.setTitle('GLOBAL GRAND CHALLENGES SUMMIT MÉXICO 2019');
    /* // Se indican los meta tags
    meta.addTags([
      // General
      {
        name: 'description',
        content:
          'La Global Grand Challenges Summit 2019 es el lanzamiento de una segunda serie de cumbres organizadas conjuntamente por las academias de ingeniería del Reino Unido, EE. UU. Y China, inspiradas en los 14 Grandes Desafíos para la Ingeniería.'
      },
      { name: 'author', content: 'Coordinación General de Innovación Gubernamental' },
      // Open Graph Metas
      { property: 'og:url', content: window.location.href },
      { property: 'og:title', content: 'GLOBAL GRAND CHALLENGES SUMMIT MÉXICO 2019' },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:description',
        content:
          'La Global Grand Challenges Summit 2019 es el lanzamiento de una segunda serie de cumbres organizadas conjuntamente por las academias de ingeniería del Reino Unido, EE. UU. Y China, inspiradas en los 14 Grandes Desafíos para la Ingeniería.'
      },
      { property: 'og:image:url', content: 'assets/images/ggcsmx/media_share.jpg' },
      { property: 'og:image:type', content: 'image/jpeg' },
      { property: 'og:image:width', content: '600' },
      { property: 'og:image:height', content: '314' }
    ]); */
  }

  ngOnInit() {
    this.navigationService.hideNavbar(this.hideNavbar);
    this.navigationService.hideFooter(this.hideNavbar);
    this.initMap();
  }

  initMap() {
    const positionDefault = [20.667967, -103.464783];
    const layer =
      'https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png';
    const zoomDefault = 12;
    const iconRetinaUrl = 'assets/images/ggcsmx/ico_pin_mapa.svg';
    const iconUrl = 'assets/images/ggcsmx/ico_pin_mapa.svg';
    const iconDefault = icon({
      iconRetinaUrl,
      iconUrl,
      iconSize: [72, 96],
      iconAnchor: [20, 80]
    });
    Marker.prototype.options.icon = iconDefault;

    this.map = new Map('mapId').setView(positionDefault, zoomDefault);
    tileLayer(layer, {}).addTo(this.map);
    this.marker = new Marker(positionDefault);
    this.map.addLayer(this.marker);
  }
}
