import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @ViewChild('navMenu') navMenu: ElementRef;
  openMenu: boolean = false;
  heightMenu: number = 64;
  @ViewChild('navPadding') navPadding: ElementRef;

  constructor(
    public navigationService: NavigationService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.heightMenu = this.navMenu.nativeElement.offsetHeight;
      this.navigationService.padding = this.heightMenu;
    }, 10);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.heightMenu = this.navMenu.nativeElement.offsetHeight;
    this.navigationService.padding = this.heightMenu;
  }

  toggleMenu(): void {
    this.openMenu = this.openMenu ? false : true;
  }

  goTo(route: string): void {
    this.navigationService.scrollSection(route);
  }

  isActive(route: string): boolean {
    return this.navigationService.currentUrl === route ? true : false;
  }

}
