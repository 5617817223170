import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewContainerRef,
  AfterViewInit,
  HostListener,
  Renderer2
} from '@angular/core';
import * as Parallax from 'parallax-js';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, timeout } from 'rxjs/operators';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('ctxTechnologyPlanning') ctxTechnologyPlanning: ElementRef;
  previousUrl: string = undefined;
  currentUrl: string = undefined;
  particles = {
    cover: {
      style: {},
      params: {}
    },
    intelligence: {
      style: {},
      params: {}
    }
  };
  directionAnimations = {
    connection: <any>[],
    technologyPlanning: <any>[]
  };
  glowSpeed: any;
  links = [];
  @ViewChild('sceneCover') scene: ElementRef;
  @ViewChild('sceneCoverBg') sceneCoverBg: ElementRef;
  @ViewChild('sceneCoverInfo') sceneCoverInfo: ElementRef;
  @ViewChild('innovation') innovation: ElementRef;
  @ViewChild('cgig') cgig: ElementRef;
  @ViewChild('axes') axes: ElementRef;
  @ViewChild('cover') cover: ElementRef;
  @ViewChild('canvasNT') canvasNT: ElementRef;
  navPadding = 0;

  constructor(
    public navigationService: NavigationService,
    private renderer: Renderer2
  ) { }

  ngAfterContentInit() {
    const parallaxInstance = new Parallax(this.scene.nativeElement);
    new Parallax(this.sceneCoverBg.nativeElement);
    new Parallax(this.sceneCoverInfo.nativeElement);
    setTimeout(() => {
      this.navigationService.scrollCurrentRoute();
    }, 50);
  }

  ngOnInit() {
    this.setSectionOffset();
    this.initNTBackground();
    // this.initNetworkJalBackground();

    this.navigationService.changed.subscribe(() => {
      this.setSectionOffset();
    });

    this.directionAnimations.connection = new Array(15).fill(null).map(x => x);
    this.directionAnimations.technologyPlanning = new Array(300).fill(null).map(x => x);

    this.particles.cover.style = {
      position: 'absolute',
      width: '33%',
      height: '100%',
      zIndex: 10,
      top: 0,
      left: 0,
      overflow: 'hidden',
      borderBottomRightRadius: '35% 55%'
    };

    this.particles.cover.params = {
      particles: {
        number: {
          value: 1300,
          density: {
            enable: true,
            value_area: 789.1476416322727
          }
        },
        color: {
          value: '#ffffff'
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0,
            color: '#000000'
          },
          polygon: {
            nb_sides: 5
          }
        },
        opacity: {
          value: 0.48927153781200905,
          random: false,
          anim: {
            enable: true,
            speed: 0.95,
            opacity_min: 0,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: true,
            speed: 2.5,
            size_min: 0,
            sync: false
          }
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: '#ffffff',
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 0.2,
          direction: 'none',
          random: true,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: 'document',
        events: {
          onhover: {
            enable: false,
            mode: 'bubble'
          },
          onclick: {
            enable: false,
            mode: 'push'
          },
          resize: false
        }
      },
      retina_detect: true
    };

    this.particles.intelligence.style = {
      position: 'absolute',
      width: '90%',
      height: '80%',
      zIndex: 10,
      top: 0,
      left: 0,
      overflow: 'hidden'
    };

    this.particles.intelligence.params = {
      particles: {
        number: {
          value: 90,
          density: {
            enable: true,
            value_area: 500
          }
        },
        color: {
          value: '#00cfff'
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0
          },
          polygon: {
            nb_sides: 5
          }
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: true,
            speed: 0.95,
            opacity_min: 0,
            sync: false
          }
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: true,
            speed: 40,
            size_min: 0.1,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#00cfff',
          opacity: 0.3,
          width: 1
        },
        move: {
          enable: true,
          speed: 3,
          direction: 'none',
          random: true,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: 'document',
        events: {
          onhover: {
            enable: false
          },
          onclick: {
            enable: false
          },
          resize: false
        }
      },
      retina_detect: true
    };
  }

  initNetworkJalBackground(): void {
    const q = sel => document.querySelector(sel);
    let c = q('#canvas-nj');

    let w = 900,
      h = 600,
      ctx = c.getContext('2d'),

      opts = {

        range: window.innerWidth > 767 ? 180 : 110,        
        baseConnections: 15,
        addedConnections: 25,        
        baseSize: window.innerWidth > 767 ? 10 : 5,
        minSize: 3,
        dataToConnectionSize: .4,
        sizeMultiplier: .6,
        allowedDist: window.innerWidth > 767 ? 45 : 25,
        baseDist: 40,
        addedDist: 50,
        connectionAttempts: 70,

        dataToConnections: 1,
        baseSpeed: .02,
        addedSpeed: .03,
        baseGlowSpeed: .4,
        addedGlowSpeed: .4,

        rotVelX: .003,
        rotVelY: .002,

        repaintColor: '#3479ff',
        connectionColor: 'hsla(191,100%,60%,.7)',
        rootColor: 'hsla(0,60%,light%,alp)',
        endColor: 'hsla(191,100%,60%,.7)',
        dataColor: 'hsla(191,100%,60%,.8)',

        wireframeWidth: .9,
        wireframeColor: 'rgba(255,255,255,0.09)',

        depth: window.innerWidth > 767 ? 250 : 150,
        focalLength: window.innerWidth > 767 ? 250 : 150,        
        vanishPoint: {
          x: w / 2,
          y: h / 2
        }

      },

      squareRange = opts.range * opts.range,
      squareAllowed = opts.allowedDist * opts.allowedDist,
      mostDistant = opts.depth + opts.range,
      sinX = 0, sinY = 0,
      cosX = 0, cosY = 0,

      connections = [],
      toDevelop = [],
      data = [],
      all = [],
      tick = 0,
      totalProb = 0,

      animating = false,

      Tau = Math.PI * 2;

    ctx.fillStyle = '#222';
    ctx.fillRect(0, 0, 900, 600);
    ctx.fillStyle = '#ccc';
    ctx.font = '50px Verdana';
    ctx.fillText('Calculating Nodes', w / 2 - ctx.measureText('Calculating Nodes').width / 2, h / 2 - 15);

    window.setTimeout(init, 4); // to render the loading screen

    function init() {

      connections.length = 0;
      data.length = 0;
      all.length = 0;
      toDevelop.length = 0;

      var connection = new Connection(0, 0, 0, opts.baseSize);
      connection.step = Connection.rootStep;
      connections.push(connection);
      all.push(connection);
      connection.link();

      while (toDevelop.length > 0) {

        toDevelop[0].link();
        toDevelop.shift();
      }

      if (!animating) {
        animating = true;
        anim();
      }
    }
    function Connection(x, y, z, size) {

      this.x = x;
      this.y = y;
      this.z = z;
      this.size = size;

      this.screen = {};

      this.links = [];
      this.probabilities = [];
      this.isEnd = false;

      this.glowSpeed = opts.baseGlowSpeed + opts.addedGlowSpeed * Math.random();
    }
    Connection.prototype.link = function () {
      if (this.size < opts.minSize)
        return this.isEnd = true;

      let links = [],
        connectionsNum = opts.baseConnections + Math.random() * opts.addedConnections | 0,
        attempt = opts.connectionAttempts,

        alpha, beta, len,
        cosA, sinA, cosB, sinB,
        pos = <any>{},
        passedExisting, passedBuffered;

      while (links.length < connectionsNum && --attempt > 0) {

        alpha = Math.random() * Math.PI;
        beta = Math.random() * Tau;
        len = opts.baseDist + opts.addedDist * Math.random();

        cosA = Math.cos(alpha);
        sinA = Math.sin(alpha);
        cosB = Math.cos(beta);
        sinB = Math.sin(beta);

        pos.x = this.x + len * cosA * sinB;
        pos.y = this.y + len * sinA * sinB;
        pos.z = this.z + len * cosB;

        if (pos.x * pos.x + pos.y * pos.y + pos.z * pos.z < squareRange) {

          passedExisting = true;
          passedBuffered = true;
          for (var i = 0; i < connections.length; ++i)
            if (squareDist(pos, connections[i]) < squareAllowed)
              passedExisting = false;

          if (passedExisting)
            for (var i = 0; i < links.length; ++i)
              if (squareDist(pos, links[i]) < squareAllowed)
                passedBuffered = false;

          if (passedExisting && passedBuffered)
            links.push({ x: pos.x, y: pos.y, z: pos.z });

        }

      }

      if (links.length === 0)
        this.isEnd = true;
      else {
        for (var i = 0; i < links.length; ++i) {

          let pos = <any>links[i],
            connection = new Connection(pos.x, pos.y, pos.z, this.size * opts.sizeMultiplier);

          this.links[i] = connection;
          all.push(connection);
          connections.push(connection);
        }
        for (var i = 0; i < this.links.length; ++i)
          toDevelop.push(this.links[i]);
      }
    }
    Connection.prototype.step = function () {

      this.setScreen();
      let screenColor = (this.isEnd ? opts.endColor : opts.connectionColor);
      let first = 30 + (((+tick) * (+this.glowSpeed)) % 30);
      screenColor = screenColor.replace('light', first.toString());
      screenColor = screenColor.replace('alp', <string><any>(.2 + (1 - (+this.screen.z) / mostDistant) * .8).toString());
      this.screen.color = screenColor;
      this.screen.color = screenColor.replace('light', (<string><any>(30 + (((+tick) * (+this.glowSpeed)) % 30))).toString().replace('alp', <string><any>(.2 + (1 - (+this.screen.z) / mostDistant) * .8).toString()));


      const q = sel => document.querySelector(sel);
      let c = q('#canvas-nj');
      let w = 900,
        h = 600,
        ctx = c.getContext('2d');

      for (var i = 0; i < this.links.length; ++i) {
        ctx.moveTo(this.screen.x, this.screen.y);
        ctx.lineTo(this.links[i].screen.x, this.links[i].screen.y);
      }
    }
    Connection.rootStep = function () {
      this.setScreen();
      this.screen.color = opts.rootColor.replace('light', (<string><any>(30 + ((tick * this.glowSpeed) % 30))).toString().replace('alp', <string><any>(.2 + (1 - this.screen.z / mostDistant) * .8).toString()));
      const q = sel => document.querySelector(sel);
      let c = q('#canvas-nj');
      let w = 900,
        h = 600,
        ctxN = c.getContext('2d');

      for (var i = 0; i < this.links.length; ++i) {
        ctxN.moveTo(this.screen.x, this.screen.y);
        ctxN.lineTo(this.links[i].screen.x, this.links[i].screen.y);
      }
    }
    Connection.prototype.draw = function () {
      const q = sel => document.querySelector(sel);
      let c = q('#canvas-nj');
      let w = 900,
        h = 600,
        ctxN = c.getContext('2d');
      ctxN.fillStyle = this.screen.color;
      ctxN.beginPath();
      ctxN.arc(this.screen.x, this.screen.y, this.screen.scale * this.size, 0, Tau);
      ctxN.fill();
    }
    function Data(connection) {

      this.glowSpeed = opts.baseGlowSpeed + opts.addedGlowSpeed * Math.random();
      this.speed = opts.baseSpeed + opts.addedSpeed * Math.random();

      this.screen = {};

      this.setConnection(connection);
    }
    Data.prototype.reset = function () {

      this.setConnection(connections[0]);
      this.ended = 2;
    }
    Data.prototype.step = function () {

      this.proportion += this.speed;

      if (this.proportion < 1) {
        this.x = this.ox + this.dx * this.proportion;
        this.y = this.oy + this.dy * this.proportion;
        this.z = this.oz + this.dz * this.proportion;
        this.size = (this.os + this.ds * this.proportion) * opts.dataToConnectionSize;
      } else
        this.setConnection(this.nextConnection);

      this.screen.lastX = this.screen.x;
      this.screen.lastY = this.screen.y;
      this.setScreen();
      this.screen.color = opts.dataColor.replace('light', (<string><any>(40 + ((tick * this.glowSpeed) % 50))).toString().replace('alp', <string><any>(.2 + (1 - this.screen.z / mostDistant) * .6).toString()));

    }
    Data.prototype.draw = function () {

      if (this.ended)
        return --this.ended; // not sre why the thing lasts 2 frames, but it does


      const q = sel => document.querySelector(sel);
      let c = q('#canvas-nj');
      let w = 900,
        h = 600,
        ctxN = c.getContext('2d');

      ctxN.beginPath();
      ctxN.strokeStyle = this.screen.color;
      ctxN.lineWidth = this.size * this.screen.scale;
      ctxN.moveTo(this.screen.lastX, this.screen.lastY);
      ctxN.lineTo(this.screen.x, this.screen.y);
      ctxN.stroke();
    }
    Data.prototype.setConnection = function (connection) {

      if (connection.isEnd)
        this.reset();

      else {

        this.connection = connection;
        this.nextConnection = connection.links[connection.links.length * Math.random() | 0];

        this.ox = connection.x; // original coordinates
        this.oy = connection.y;
        this.oz = connection.z;
        this.os = connection.size; // base size

        this.nx = this.nextConnection.x; // new
        this.ny = this.nextConnection.y;
        this.nz = this.nextConnection.z;
        this.ns = this.nextConnection.size;

        this.dx = this.nx - this.ox; // delta
        this.dy = this.ny - this.oy;
        this.dz = this.nz - this.oz;
        this.ds = this.ns - this.os;

        this.proportion = 0;
      }
    }
    Connection.prototype.setScreen = Data.prototype.setScreen = function () {

      var x = this.x,
        y = this.y,
        z = this.z;

      // apply rotation on X axis
      var Y = y;
      y = y * cosX - z * sinX;
      z = z * cosX + Y * sinX;

      // rot on Y
      var Z = z;
      z = z * cosY - x * sinY;
      x = x * cosY + Z * sinY;

      this.screen.z = z;

      // translate on Z
      z += opts.depth;

      this.screen.scale = opts.focalLength / z;
      this.screen.x = opts.vanishPoint.x + x * this.screen.scale;
      this.screen.y = opts.vanishPoint.y + y * this.screen.scale;

    }
    function squareDist(a, b) {

      var x = b.x - a.x,
        y = b.y - a.y,
        z = b.z - a.z;

      return x * x + y * y + z * z;
    }

    function anim() {

      window.requestAnimationFrame(anim);


      const q = sel => document.querySelector(sel);
      let c = q('#canvas-nj');

      if (c === null) {
        return false;
      }

      let w = 900,
        h = 600,
        ctxN = c.getContext('2d');



      ctxN.globalCompositeOperation = 'source-over';
      ctxN.fillStyle = opts.repaintColor;
      ctxN.fillRect(0, 0, 900, 600);

      // console.log("w", w);
      // console.log("h", h);

      ++tick;

      var rotX = tick * opts.rotVelX,
        rotY = tick * opts.rotVelY;

      cosX = Math.cos(rotX);
      sinX = Math.sin(rotX);
      cosY = Math.cos(rotY);
      sinY = Math.sin(rotY);

      if (data.length < connections.length * opts.dataToConnections) {
        var datum = new Data(connections[0]);
        data.push(datum);
        all.push(datum);
      }

      ctxN.globalCompositeOperation = 'lighter';
      ctxN.beginPath();
      ctxN.lineWidth = opts.wireframeWidth;
      ctxN.strokeStyle = opts.wireframeColor;
      all.map(function (item) { item.step(); });
      ctxN.stroke();
      ctxN.globalCompositeOperation = 'source-over';
      all.sort(function (a, b) { return b.screen.z - a.screen.z });
      all.map(function (item) { item.draw(); });
    }

    opts.vanishPoint.x = (w = c.width = window.innerWidth) / 2;
    opts.vanishPoint.y = (h = c.height = window.innerHeight) / 2;
    let ctxN = c.getContext('2d');
    ctxN.fillRect(0, 0, 900, 600);
  }

  initNTBackground(): void {
    const q = sel => document.querySelector(sel);
    const canvas = q('#canvas-nt');
    const ctx = canvas.getContext('2d');
    const PI2 = Math.PI * 2;
    const FF = PI2 / 8;
    canvas.fillStyle = '#0059ff';

    let width = this.canvasNT.nativeElement.offsetWidth;
    let height =
      this.canvasNT.nativeElement.offsetHeight -
      (this.canvasNT.nativeElement.offsetHeight * 5) / 100;
    let widthHalf;
    let heightHalf;

    let phase = 0;
    let trails = [];
    const trailWidth = 20;

    const getAngle = () => {
      const angle = PI2 * Math.random();
      return angle - (angle % FF);
    };

    const create = () => {
      let num = 400;

      while (num--) {
        trails.push({
          dead: true,
          x: widthHalf,
          y: heightHalf,
          width: trailWidth,
          vel: 1 + 1 * Math.random(),
          angle: getAngle()
        });
      }
    };

    const reset = () => {
      trails = [];
      width = this.canvasNT.nativeElement.offsetWidth - 10;
      height =
        this.canvasNT.nativeElement.offsetHeight -
        (this.canvasNT.nativeElement.offsetHeight * 5) / 100;
      widthHalf = width * 0.5;
      heightHalf = height * 0.5;

      canvas.width = width;
      canvas.height = height;

      create();
    };

    const update = (trail, right, bottom) => {
      const shouldChange = Math.random() > 0.97;
      const incAngle = Math.random() > 0.5;

      trail.dead =
        trail.x < 0 ||
        trail.x > right ||
        trail.y < 0 ||
        trail.y > height ||
        trail.width < 0.2;

      if (shouldChange && incAngle) {
        trail.angle += FF;
      } else if (shouldChange) {
        trail.angle -= FF;
      }

      trail.width *= 0.98;
    };

    const render = (trail, ctx, phase) => {
      let { x, y, length, vel, angle } = trail;

      const scale = 0.002;
      const n = -0.46633127596828723;
      const h = 180 * n;

      ctx.beginPath();
      ctx.lineWidth = trail.width;
      ctx.strokeStyle = 'rgba(255, 255, 255, 0.7)';
      ctx.strokeStyle = 'rgba(20, 255, 255, 0.7)';

      ctx.moveTo(trail.x, trail.y);

      trail.x += Math.cos(angle) * vel;
      trail.y += Math.sin(angle) * vel;

      ctx.lineTo(trail.x, trail.y);
      ctx.stroke();
      ctx.closePath();
    };

    const clear = () => {
      ctx.fillStyle = 'rgba(0, 89, 255, 0.1)';
      ctx.fillRect(0, 0, 900, 600);
    };

    const loop = () => {
      clear();

      trails.forEach(t => {
        update(t, width, height);
        render(t, ctx, phase);
      });

      trails = trails.filter(t => !t.dead);

      if (!trails.length) {
        reset();
      }

      phase += 0.004;

      requestAnimationFrame(loop);
    };

    reset();
    loop();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setSectionOffset();
  }

  setSectionOffset(): void {
    this.navigationService.offset['innovacion-gubernamental'] =
      this.cover.nativeElement.offsetHeight + 120;
    this.navigationService.offset['direcciones'] = this.cgig.nativeElement.offsetTop;
  }

  goTo(route: string): void {
    this.navigationService.scrollSection(route);
  }

  public onIntersection({
    target,
    visible
  }: {
    target: Element;
    visible: boolean;
  }): void {
    this.renderer.addClass(target, visible ? 'active' : 'inactive');
    this.renderer.removeClass(target, visible ? 'inactive' : 'active');
  }
}
