import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { OrganizationChartComponent } from './organization-chart/organization-chart.component';
import { EmptyComponent } from './layout/empty/empty.component';
import { GgcsmxComponent } from './ggcsmx/ggcsmx.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'innovacion-gubernamental',
        component: EmptyComponent
      },
      {
        path: 'direcciones',
        component: EmptyComponent
      }
    ]
  },
  { path: 'home', component: HomeComponent },
  { path: 'organigrama', component: OrganizationChartComponent },
  /* { path: 'GGCSMX', component: GgcsmxComponent },
  { path: 'ggcsmx', component: GgcsmxComponent }, */
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
