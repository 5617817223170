import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationService } from './services/navigation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    "[class]":"cursorKonamiCode()" 
  }, 
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'cgig-landing';
  navbarVisibilitySubs: Subscription;
  footerVisibilitySubs: Subscription;
  hideNavbar: boolean = false;
  hideFooter: boolean = false;
  konamiCodeActive: boolean = false;

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    this.navbarVisibilitySubs = this.navigationService.onHideNavbar.subscribe(
      hideNabvar => (this.hideNavbar = hideNabvar)
    );

    this.footerVisibilitySubs = this.navigationService.onHideFooter.subscribe(
      hideFooter => (this.hideFooter = hideFooter)
    );
  }

  ngOnDestroy() {
    this.navbarVisibilitySubs.unsubscribe();
    this.footerVisibilitySubs.unsubscribe();
  }

  konamiCode(): void {

    if (!this.konamiCodeActive) {
      this.konamiCodeActive = true;
      this.navigationService.konamiCodeActive = true;      
    }
    
  }

  cursorKonamiCode(): string {
    return this.konamiCodeActive ? 'konamiCode' : 'default';    
  }
}
